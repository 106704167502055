<template>
  <div class="px-10 py-8">
    <v-row class="font-weight-bold">
      <v-col cols="12" md="2">
        <v-card class="font-weight-bold">
          <div class="text-center pt-5">教室連通率</div>
          <div class="px-10 pb-10 pt-5 d-flex justify-center align-center">
            <v-row justify="center" align="center">
              <v-col cols="12" sm="6" md="12" class="text-center">
                <v-progress-circular
                  :rotate="270"
                  :size="120"
                  :width="15"
                  :value="connectedClassDeviceRate"
                  color="primary"
                  class="text-h5"
                >
                  {{ connectedClassDeviceRate }}%
                </v-progress-circular>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="10"
                :class="{ 'mt-3': !$vuetify.breakpoint.xs }"
                class="accent--text"
              >
                <div class="d-flex justify-space-between">
                  <div>教室連通數</div>
                  <div>{{ connectedClassDeviceAmount }}</div>
                </div>
                <div class="mt-2 d-flex justify-space-between">
                  <div>教室數</div>
                  <div>{{ schoolClassAmount }}</div>
                </div>
              </v-col>
            </v-row>
          </div>
        </v-card>
      </v-col>
      <v-col cols="12" md="10">
        <v-card class="pa-5" width="100%">
          <div class="d-flex">
            <div class="text-h6 accent--text font-weight-bold">
              {{ theCity.name }}學校列表
            </div>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              class="mr-4"
              @click="download"
              :loading="loadingDownload"
              >下載報表 <v-icon small class="ml-2">mdi-download</v-icon>
            </v-btn>
          </div>
          <v-col cols="12" sm="6" class="ml-auto">
            <v-text-field
              v-model="searchWords"
              placeholder="搜尋名稱"
              filled
              rounded
              dense
              append-icon="mdi-magnify"
              hide-details=""
              class="text-right mb-2"
            />
          </v-col>
          <div class="text-center mt-10" v-if="loadingSchools">
            <v-progress-circular color="grey" indeterminate class="mx-auto" />
          </div>
          <v-data-table
            :headers="headers"
            :items="filterSchools"
            hide-default-footer
            :items-per-page="-1"
            :loading="loadingData"
            loading-text="載入中..."
            no-data-text="( 無資料 )"
            :expanded.sync="expanded"
            show-expand
            item-key="schoolId"
            @item-expanded="getDetail"
          >
            <template v-slot:[`item.classroomConnectionRate`]="{ item }">
              {{ item.classroomConnectionRate + '%' }}
            </template>
            <template v-slot:expanded-item="{ headers, item }">
              <td class="pa-4" :colspan="headers.length">
                <div class="d-flex justify-space-between">
                  <div class="font-weight-bold">連通教室</div>
                  <div class="d-flex align-center">
                    <v-icon color="success">mdi-circle-medium</v-icon>連通
                    <v-icon color="#acacac" class="ml-1"
                      >mdi-circle-medium</v-icon
                    >未連通
                  </div>
                </div>
                <v-divider class="mb-2"></v-divider>
                <div class="text-center" v-if="item.loadingDetail">
                  <v-progress-circular color="grey" indeterminate />
                </div>
                <div
                  class="text-center"
                  v-else-if="!(item.classrooms && item.classrooms.length)"
                >
                  - 無 -
                </div>
                <v-chip
                  v-else
                  v-for="classroom in item.classrooms"
                  :color="classroom.classroomSuccess ? 'success' : ''"
                  class="ml-1 mb-1"
                >
                  {{ classroom.classroomName }}
                </v-chip>
              </td>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import City from '@/api/admin/City'
import Connection from '@/api/admin/Connection'

export default {
  data() {
    return {
      theCity: {},
      schools: [],
      loadingGetCities: false,
      loadingSchools: false,
      loadingSchoolDevices: false,
      loadingDownload: false,
      headers: [
        { text: '學校名稱', value: 'schoolName' },
        { text: '教室數', value: 'classroomCount', align: 'center' },
        {
          text: '教室連通數',
          value: 'classroomConnectionCount',
          align: 'center'
        },
        {
          text: '教室連通率',
          value: 'classroomConnectionRate',
          align: 'center'
        },
        { text: '', value: 'data-table-expand' }
      ],
      searchWords: '',
      expanded: [],
      classroomDetails: [],
      loadingData: false
    }
  },
  computed: {
    connectedClassDeviceAmount() {
      return this.schools.reduce((acc, cur) => {
        return acc + cur.classroomConnectionCount
      }, 0)
    },
    schoolClassAmount() {
      return this.schools.reduce((acc, cur) => {
        return acc + cur.classroomCount
      }, 0)
    },
    connectedClassDeviceRate() {
      return (
        Math.ceil(
          (this.connectedClassDeviceAmount / this.schoolClassAmount) * 10000
        ) / 100
      )
    },
    filterSchools() {
      let filterSchools = this.schools.filter((school) =>
        school.schoolName.includes(this.searchWords)
      )
      return filterSchools
    }
  },
  async mounted() {
    await this.getCities()
    await this.getConnectionRate()
    // await this.getClassroomDetail()
  },
  methods: {
    async getCities() {
      try {
        const { data } = await City.list()
        if (data.length === 1) this.theCity = data[0]
      } catch (error) {
        console.error(error)
      }
    },
    async getConnectionRate() {
      try {
        this.loadingData = true
        const { data } = await Connection.list()
        this.schools = data
      } catch (error) {
        console.error(error)
      } finally {
        this.loadingData = false
      }
    },
    async getDetail(val) {
      if (val.value) {
        let index = this.schools.findIndex(
          ({ schoolId }) => schoolId === val.item.schoolId
        )
        try {
          this.$set(this.schools, index, {
            ...this.schools[index],
            loadingDetail: true
          })
          const { data } = await Connection.classroomDetail(
            this.schools[index].schoolId
          )
          this.$set(this.schools, index, {
            ...this.schools[index],
            classrooms: data
          })
        } catch (error) {
          console.error(error)
        } finally {
          this.$set(this.schools, index, {
            ...this.schools[index],
            loadingDetail: false
          })
        }
      }
    },
    async download() {
      try {
        let fileType = 'csv'
        this.loadingDownload = true
        const { data } = await Connection.downloadClassroomDetail(fileType)
        const url = window.URL.createObjectURL(
          new Blob([(data ? '\ufeff' : '') + data], {
            type: 'text/csv;charset=utf-8;'
          })
        )
        const link = document.createElement('a')
        link.href = url
        let fileName = this.getResponseFilename(fileType)
        link.setAttribute('download', fileName) // or any other extension
        document.body.appendChild(link)
        link.click()
      } catch (error) {
        console.error(error)
      } finally {
        this.loadingDownload = false
      }
    },
    getResponseFilename(fileType) {
      const date = moment().format('YYYY-MM-DD_HH-mm-ss')
      return (
        (this.theCity.name ? `${this.theCity.name}_` : '') +
        `教室連通率_${date}.${fileType}`
      )
    }
  }
}
</script>
