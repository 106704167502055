import settings from '@/settings'
import axios from 'axios'

/**
 * Admin 後台 縣市資訊
 */
class _Connection {
  /**
   * 縣市列表
   */
  list() {
    return axios.get(`${settings.api.fullPath}/ems/report/connection/school`)
  }

  download(fileType) {
    return axios.get(
      `${settings.api.fullPath}/ems/report/connection/school.${fileType}`
    )
  }

  classroomDetail(schoolId) {
    return axios.get(
      `${settings.api.fullPath}/ems/report/connection/school/${schoolId}/classroom-detail`
    )
  }

  downloadClassroomDetail(fileType) {
    return axios.get(
      `${settings.api.fullPath}/ems/report/connection/school/classroom-detail.${fileType}`
    )
  }
}

var Connection = new _Connection()
export default Connection
