import settings from '@/settings'

/**
 * Admin 後台 縣市資訊
 */
class _City {
  /**
   * 縣市列表
   */
  list() {
    return axios.get(settings.api.fullPath + `/ems/city/`)
  }
}

var City = new _City()
export default City
